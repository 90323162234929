import { type MessageDescriptor } from 'react-intl-next';

import { type LoadingAnimationProps } from '../common/ui/ai-answer-loading/loading-animation';
import { type AiBrand } from '../constants';
import type { CuratedDefinitionOptions } from '../v2/definitions/curated/types';

export interface AIAnswerCacheType {
	query: string;
	additionalContext: string;
	collapsed?: boolean;
	liked?: boolean;
	reported?: boolean;
	data: NLPSearchType;
	fromFollowUp?: boolean;
	sourcesExpanded?: boolean;
	recentWorkListExpanded?: boolean;
}

export enum NLPSearchResultFormat {
	PLAINTEXT = 'PLAIN_TEXT',
	ADF = 'ADF',
	JSON = 'JSON',
	MARKDOWN = 'MARKDOWN',
}

export enum NLPSearchJSONResultFormat {
	WHO = 'who',
	WORK = 'work',
	TEAM = 'team',
	PERSON = 'person',
}

export enum NLPSearchDisclaimerType {
	WhoDisclaimer = 'WHO_QUESTION',
}

export enum NLPSearchErrorState {
	NoAnswer = 'NO_ANSWER',
	NoAnswerKeywords = 'NO_ANSWER_KEYWORDS',
	NoAnswerSearchResults = 'NO_ANSWER_SEARCH_RESULTS',
	NoAnswerHydration = 'NO_ANSWER_HYDRATION',
	NoAnswerRelevantContent = 'NO_ANSWER_RELEVANT_CONTENT',
	NoAnswerOpenAIResponseError = 'NO_ANSWER_OPEN_AI_RESPONSE_ERR',
	NoAnswerOpenAIRateLimitUserAbuse = 'OPENAI_RATE_LIMIT_USER_ABUSE',
	NoAnswerWhoQuestion = 'NO_ANSWER_WHO_QUESTION',
	SubjectiveQuery = 'SUBJECTIVE_QUERY',
	AcceptableUseViolation = 'ACCEPTABLE_USE_VIOLATIONS',
	AIDisabled = 'AI_DISABLED',
	// These values are not returned from the BE and are only used by the FE
	Default = 'DEFAULT', // Value at component initialization
	NetworkError = 'NETWORK_ERROR', // Value if the network call fails
	TimeoutError = 'TIMEOUT_ERROR', // Value if the network call times out
}

export enum NLPSearchResultContainerDataTypeContainerType {
	ConfluenceSpace = 'confluenceSpace',
	JiraProject = 'jiraProject',
}

export interface NLPSearchWhoResultContainerDataType {
	name: string;
	containerType: NLPSearchResultContainerDataTypeContainerType | null;
	url: string | null;
	iconUrl: string | null;
}

export interface NLPSearchWhoResultCollaboratorDataType {
	name: string;
	picture: string | null;
	title: string | null;
	url: string | null;
	accountId: string | null;
	department: string | null;
	email: string | null;
}
export interface NLPSearchWhoResultTeamDataType {
	name: string;
	avatarUrl: string | null;
	url: string | null;
	memberCount: number;
}

export interface NLPSearchWhoResultDataType {
	account_id?: string;
	name: string;
	job_title?: string | null;
	profile_url?: string | null;
	avatar_url?: string | null;
	email?: string | null;
	location?: string | null;
	department?: string | null;
	organization?: string | null;
	time?: string | null;
	collaborators?: NLPSearchWhoResultCollaboratorDataType[];
	containers?: NLPSearchWhoResultContainerDataType[];
	teams?: NLPSearchWhoResultTeamDataType[];
	viewAllLink?: string;
	recentActivity?: NLPSearchWorkResultRecentActivityDataType[];
}

export interface NLPSearchWhoResultType {
	user_metadata: NLPSearchWhoResultDataType[];
}

export interface NLPSearchPersonResultDataType {
	id: string;
	name: string;
	title?: string;
	avatarUrl?: string;
	zoneInfo?: string;
}

export interface NLPSearchPersonResultType {
	user_metadata_only_cards: NLPSearchPersonResultDataType[];
}

export interface NLPSearchTeamResultDataType {
	name: string;
	id: string;
	avatarUrl: string | null;
	url: string | null;
	memberCount: number;
	members: NLPSearchWhoResultCollaboratorDataType[];
	recentActivity: NLPSearchWorkResultRecentActivityDataType[];
}

export interface NLPSearchTeamResultType {
	team_metadata: NLPSearchTeamResultDataType;
}

export interface NLPSearchWorkResultRecentActivityDataType {
	url: string;
	iconUrl: string;
	title: string;
	containerName: string;
	event: 'created' | 'edited' | 'commented' | 'updated' | 'worked';
	contributors: { name: string; id: string }[];
	timestamp: string;
	othersCount: number;
}

export interface NLPSearchWorkResultDataType {
	name: string;
	job_title?: string | null;
	profile_url?: string | null;
	avatar_url?: string | null;
	email?: string | null;
	location?: string | null;
	department?: string | null;
	organization?: string | null;
	time?: string | null;
	viewAllLink: string;
	recentActivity: NLPSearchWorkResultRecentActivityDataType[];
}

export interface NLPSearchWorkResultType {
	user_metadata_with_activity: NLPSearchWorkResultDataType[];
}

export type NLPSearchJSONResultType =
	| NLPSearchWorkResultType['user_metadata_with_activity']
	| NLPSearchWhoResultType['user_metadata']
	| NLPSearchTeamResultType['team_metadata']
	| NLPSearchPersonResultType['user_metadata_only_cards'];

export interface NLPSearchResultSource {
	ari?: string;
	id: string;
	title: string;
	url: string;
	iconUrl: string | null;
	type: string;
	lastModified: string | null;
	spaceName: string | null;
	spaceUrl: string | null;
}

export interface NLPSearchResultType {
	nlpResult: string;
	sources: NLPSearchResultSource[] | null;
}

export enum NLPSearchCustomDefinitionType {
	CURATED = 'CURATED',
	PRECOMPUTED = 'PRECOMPUTED',
}

// will work with BE to standardize nlpResult in schema between gql and REST
export interface NLPSearchType {
	// what is returned from graphQL endpoint
	nlpResults?: NLPSearchResultType[];
	// what is returned from streaming endpoint
	nlpResult?: string;
	format: NLPSearchResultFormat | null;
	uniqueSources: NLPSearchResultSource[];
	errorState: NLPSearchErrorState | null;
	errorCode?: number;
	disclaimer: NLPSearchDisclaimerType | null;
	nlpFollowUpResults?: NLPFollowUpResponse | null;
	nlpResultEditor?: {
		name: string;
		id: string;
	};
	customDefinitionType?: NLPSearchCustomDefinitionType;
	extraAPIAnalyticsAttributes?: {
		[key: string]: any;
	};
}

export interface NLPFollowUpResponse {
	followUps: string[];
}

export interface SearchAIAnswerQueryType {
	nlpSearch: NLPSearchType;
}

export interface SearchAIAnswerQueryVariables {
	query: string;
	locale: string;
	cloudIdARI: string;
	experience: string;
	followUpsEnabled?: boolean;
	filters?: parsedFiltersType;
	additional_context?: string;
}

export enum AIAnswerQueryStreamType {
	AnswerType = 'ANSWER_TYPE',
	AnswerPart = 'ANSWER_PART',
	FinalResponse = 'FINAL_RESPONSE',
}

/**
 * Overloading the return type of the query function to allow for setting up
 * a v2 getAnswer.
 */
export type AIAnswerQueryStreamAnswerType = {
	type: AIAnswerQueryStreamType.AnswerType;
	message: { format: NLPSearchResultFormat };
};
export type AIAnswerQueryStreamMarkdownPart = {
	type: AIAnswerQueryStreamType.AnswerPart;
	message: { nlpResult: string };
};
export type AIAnswerQueryFinalResponsePart = {
	type: AIAnswerQueryStreamType.FinalResponse;
	message: NLPSearchType;
};
export type AIAnswerQueryStreamMarkdownFinalPart = {
	state: 'complete';
	value: {
		type: AIAnswerQueryStreamType.FinalResponse;
		message: NLPSearchType;
	};
};
export type AIAnswerQueryStreamMarkdownFailedBackend = {
	state: 'failed';
	reason: 'backend';
	details: string;
	errorCode: number;
};
export type AIAnswerQueryStreamMarkdownFailedParsing = {
	state: 'failed';
	reason: 'parsing';
	error: unknown;
};
export type AIAnswerQueryStreamMarkdownFailedAborted = {
	state: 'failed';
	reason: 'aborted';
};
export type AIAnswerQueryStreamTimoutError = {
	state: 'failed';
	reason: 'timeout';
};
export type AIAnswerQueryStreamMarkdownFailedError = {
	state: 'failed';
	reason: 'error';
	details: string;
	error: Error;
};
export type AIAnswerQueryStreamMarkdownFailedUnknown = {
	state: 'failed';
	reason: 'unknown';
};
export type AIAnswerQueryStreamEntry =
	| AIAnswerQueryStreamAnswerType
	| AIAnswerQueryStreamMarkdownPart
	| AIAnswerQueryFinalResponsePart;
export type AIAnswerQueryStreamEnd =
	| AIAnswerQueryStreamMarkdownFinalPart
	| AIAnswerQueryStreamMarkdownFailedBackend
	| AIAnswerQueryStreamMarkdownFailedParsing
	| AIAnswerQueryStreamMarkdownFailedAborted
	| AIAnswerQueryStreamMarkdownFailedError
	| AIAnswerQueryStreamTimoutError
	| AIAnswerQueryStreamMarkdownFailedUnknown;

export type AIAnswerQueryStream = AsyncGenerator<
	AIAnswerQueryStreamEntry,
	AIAnswerQueryStreamEnd,
	void
>;

export enum AIAnswerAPIType {
	STREAM = 'STREAM',
}

export type AIAnswerQueryStreamResponse = {
	type: AIAnswerAPIType.STREAM;
	stream: AIAnswerQueryStream;
};

/**
 * The return type is overloaded here to minimise the impact of the change towards
 * v2 with streaming.
 */
export type AIAnswerQueryFunctionType = ({
	variables,
}: {
	variables: Omit<SearchAIAnswerQueryVariables, 'cloudIdARI'>;
}) =>
	| Promise<SearchAIAnswerQueryType>
	| AIAnswerQueryStreamResponse
	| Promise<SearchAIAnswerQueryType | AIAnswerQueryStreamResponse>;

export type ReadingAidsMenuOptionControlTypes = 'toggle';

type ReadingAidsMenuOptionType = {
	label: MessageDescriptor;
	onChange: () => void;
	description?: MessageDescriptor;
};

interface ReadingAidsMenuToggleType extends ReadingAidsMenuOptionType {
	type: Extract<ReadingAidsMenuOptionControlTypes, 'toggle'>;
	toggleState: boolean;
}

export type ReadingAidsOptions = {
	enableSmartLinkSources?: boolean;
	definitionCurationOptions?: CuratedDefinitionOptions;
	menuOptions?: Array<ReadingAidsMenuToggleType>;
	showBanner?: boolean;
	loadingHighlightOption?: LoadingAnimationProps;
	onFollowUpCallback?: (followUp: string) => void;
	contentARI?: string;
};

export type commonAttributesType = {
	cloudId: string;
	orgId: string;
	source: string;
	query: string;
	queryHash: string;
	baseQueryHash: string;
	searchSessionId: string;
	answerString: string;
	answerLength: number;
	answerWordCount: number;
	answerFormat: NLPSearchResultFormat | null;
	answerCardType: string;
	sources: NLPSearchResultSource[];
	followUps: string[];
	errorState: NLPSearchErrorState | null;
	errorCode?: number;
	extraAttributes: {
		[key: string]: any;
	};
	apiSource: 'assistance-service' | 'cc-search-nlp' | 'xp-knowledge-discovery';
	has3PSources?: boolean;
	appliedFilters?: parsedFiltersType;
	workspaceId: string;
	sessionId: string;
	brand: AiBrand;
};

export type parsedFiltersType = {
	'@type': string;
	[key: string]: string | string[] | boolean | undefined;
}[];

export type Callbacks = {
	onAIAnswerSourceClick?: () => void;
	onFollowUpSuggestionClick?: () => void;
};
