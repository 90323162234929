import {
	type AIAnswerQueryStream,
	type AIAnswerQueryStreamEntry,
	type AIAnswerQueryStreamMarkdownFinalPart,
	AIAnswerQueryStreamType,
	NLPSearchErrorState,
	NLPSearchResultFormat,
	type NLPSearchResultSource,
	type NLPSearchType,
	type SearchAIAnswerQueryType,
	type SearchAIAnswerQueryVariables,
} from '../ai-answer-dialog/types';
import { ASSISTANCE_SERVICE_API_BASE_URL, SAIN_STREAMING_API } from '../constants';

import {
	type AgentError,
	type AgentResponse,
	isStreamAnswerPart,
	isStreamError,
	isStreamFollowUp,
	isStreamResponse,
	type StreamError,
	type StreamMessage,
} from './types';

const identityUserCloudARIPrefix = 'ari:cloud:identity::user/';

function getAgentAndExperience(isReadingAids: boolean) {
	return isReadingAids
		? {
				named_id: 'reading_aids_agent',
				experience: 'reading-aids',
			}
		: {
				named_id: 'sain_agent',
				experience: 'sain',
			};
}

function mapMimeTypeToFormat(mimeType: string): NLPSearchResultFormat {
	switch (mimeType) {
		case 'text/markdown':
			return NLPSearchResultFormat.MARKDOWN;
		case 'text/adf':
			return NLPSearchResultFormat.ADF;
		case 'text/json':
			return NLPSearchResultFormat.JSON;
		default:
			return NLPSearchResultFormat.MARKDOWN;
	}
}

function mapErrorToSain(error: StreamError | AgentError): NLPSearchErrorState {
	const errorMessage =
		'message_template' in error ? error.message_template : error.message.message_template;

	switch (errorMessage) {
		case 'NO_ANSWER_LLM':
			return NLPSearchErrorState.NoAnswer;
		case 'NO_ANSWER_PEOPLE':
			return NLPSearchErrorState.NoAnswerWhoQuestion;
		case 'NO_ANSWER_KEYWORDS':
			return NLPSearchErrorState.NoAnswerKeywords;
		case 'NO_ANSWER_SEARCH_QA_PLUGIN':
			return NLPSearchErrorState.NoAnswerSearchResults;
		case 'ACCEPTABLE_USE_VIOLATIONS':
			return NLPSearchErrorState.AcceptableUseViolation;
		case 'OPENAI_RATE_LIMIT_USER_ABUSE':
			return NLPSearchErrorState.NoAnswerOpenAIRateLimitUserAbuse;
		case 'FEATURE_DISABLED_ON_SITE':
			return NLPSearchErrorState.AIDisabled;
		default:
			return NLPSearchErrorState.NetworkError;
	}
}

function mapMessageToNLPSearchType(message: AgentResponse): NLPSearchType {
	return {
		nlpResult: message.message.content,
		uniqueSources:
			message.message.sources?.map(
				(source): NLPSearchResultSource => ({
					ari: source.ari,
					id: source.id?.toString(),
					title: source.title,
					type: source.type,
					url: source.url,
					lastModified: source.lastModified,
					iconUrl: null,
					spaceName: null,
					spaceUrl: null,
				}),
			) ?? [],
		disclaimer: message.message.message_metadata?.disclaimer ?? null,
		errorState: null,
		format: mapMimeTypeToFormat(message.message.content_mime_type),
		extraAPIAnalyticsAttributes: {
			message_id: message.message.id,
			conversational_channel_id: message.message.conversation_channel_id,
			experience_id: message.message.experience_id,
			user_id: message.message.user_ari.replace(identityUserCloudARIPrefix, ''),
		},
	};
}

function mapStreamResponse(response: StreamMessage):
	| AIAnswerQueryStreamEntry
	| AIAnswerQueryStreamMarkdownFinalPart['value']
	| {
			type: 'other';
	  }
	| {
			type: 'follow-up';
			queries: string[];
	  } {
	if (isStreamError(response)) {
		return {
			type: AIAnswerQueryStreamType.FinalResponse,
			message: {
				errorState: mapErrorToSain(response),
				errorCode: response.message.status_code,
				disclaimer: null,
				format: NLPSearchResultFormat.MARKDOWN,
				uniqueSources: [],
			},
		};
	}

	if (isStreamAnswerPart(response)) {
		return {
			type: AIAnswerQueryStreamType.AnswerPart,
			message: {
				nlpResult: response.message.content,
			},
		};
	}

	if (isStreamResponse(response)) {
		return {
			type: AIAnswerQueryStreamType.FinalResponse,
			message: mapMessageToNLPSearchType(response.message),
		};
	}

	if (isStreamFollowUp(response)) {
		return {
			type: 'follow-up',
			queries: response.message.follow_up_queries ?? [],
		};
	}

	return {
		// Don't currently handle other types, such as PLUGIN_INVOCATION, TRACE etc
		type: 'other',
	};
}

export type FetchConfig = {
	hostName?: string;
	credentials?: 'omit' | 'same-origin' | 'include';
	entity_ari?: string;
	headers?: { [key: string]: string };
	timeout?: number;
};

type AskSainFetchHandlerArgs = {
	fetchReturn?: Promise<Response>;
	variables?: SearchAIAnswerQueryVariables;
	fetchConfig?: FetchConfig;
};

export async function* sainStreamFetchHandler({
	fetchReturn,
	variables,
	fetchConfig,
}: AskSainFetchHandlerArgs): AIAnswerQueryStream {
	try {
		const { query, locale, experience, filters, cloudIdARI, followUpsEnabled } = variables || {};
		const url = `${fetchConfig?.hostName || ''}${SAIN_STREAMING_API}`;
		const response = await (fetchReturn
			? fetchReturn
			: fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						query,
						filters,
						locations: [cloudIdARI],
						locale,
						experience,
						followUpsEnabled,
					}),
					credentials: fetchConfig?.credentials || 'same-origin', // fetch() uses 'same-origin' by default
				}));

		if (!response.ok) {
			// For non 200 status codes
			return {
				state: 'failed',
				reason: 'backend',
				details: 'Unhandled error response received',
				errorCode: response.status,
			};
		}

		if (!response.body) {
			return {
				state: 'failed',
				reason: 'backend',
				details: 'response.body missing',
				errorCode: response.status,
			};
		}

		try {
			const reader = response.body.getReader();
			const decoder = new TextDecoder('utf-8');
			let buffer = '';
			let done = false;

			let finalResponse: AIAnswerQueryStreamMarkdownFinalPart['value'] | undefined;

			while (!done) {
				const { value, done: doneReading } = await reader.read();
				done = doneReading;
				const chunkValue = decoder.decode(value);
				buffer = buffer + chunkValue;

				// Split the buffer by line breaks
				const lines = buffer.split('\n');
				// Process all complete lines, except for the last one (which might be incomplete)
				while (lines.length > 1) {
					const line = lines.shift()!;
					const parsedData = JSON.parse(line) as
						| AIAnswerQueryStreamEntry
						| AIAnswerQueryStreamMarkdownFinalPart['value'];

					if (
						parsedData.type === AIAnswerQueryStreamType.AnswerType ||
						parsedData.type === AIAnswerQueryStreamType.AnswerPart
					) {
						yield parsedData;
					} else {
						finalResponse = parsedData;
						done = true;
					}
				}
				// Keep the last (potentially incomplete) line in the buffer
				buffer = lines[0];
			}

			if (finalResponse?.type !== AIAnswerQueryStreamType.FinalResponse) {
				return {
					state: 'failed',
					reason: 'parsing',
					error: 'Unexpected final response value',
				};
			}

			return { state: 'complete', value: finalResponse };
		} catch (parsingError: unknown) {
			return { state: 'failed', reason: 'parsing', error: parsingError };
		}
	} catch (error: unknown) {
		if (error instanceof DOMException && error.name === 'AbortError') {
			// this is likely an abort error
			return { state: 'failed', reason: 'aborted' };
		}

		if (error instanceof Error) {
			return { state: 'failed', reason: 'error', details: error.message, error };
		}

		return { state: 'failed', reason: 'unknown' };
	}
}

const getAgentInput = ({
	variables,
	fetchConfig,
}: {
	variables: SearchAIAnswerQueryVariables | undefined;
	fetchConfig: FetchConfig | undefined;
}) => {
	const { query, locale, additional_context } = variables || {};
	return {
		content: query,
		locale,
		additional_context,
		entity_ari: fetchConfig?.entity_ari,
	};
};

export async function* sainStreamFetchHandlerAssistanceService({
	fetchReturn,
	variables,
	isReadingAids,
	fetchConfig,
	generatorConfig = {
		yieldAnswerPart: true,
		yieldFinalResponse: false,
	},
	onStreamFinalResponse,
}: AskSainFetchHandlerArgs & {
	isReadingAids: boolean;
	generatorConfig?: {
		yieldAnswerPart?: boolean;
		yieldFinalResponse?: boolean;
	};
	onStreamFinalResponse?: () => void;
}): AIAnswerQueryStream {
	try {
		const { filters } = variables || {};
		const { experience, named_id } = getAgentAndExperience(isReadingAids);

		const url = `${
			fetchConfig?.hostName || ''
		}${ASSISTANCE_SERVICE_API_BASE_URL}/chat/v1/invoke_agent/stream`;
		const response = await (fetchReturn
			? fetchReturn
			: fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'X-Product': 'confluence',
						'X-Experience-Id': experience,
						...(fetchConfig?.headers ? fetchConfig.headers : {}),
					},
					body: JSON.stringify({
						recipient_agent_named_id: named_id,
						agent_input: {
							...getAgentInput({ variables, fetchConfig }),
							filters,
						},
					}),
					credentials: fetchConfig?.credentials || 'same-origin', // fetch() uses 'same-origin' by default
					signal: fetchConfig?.timeout ? AbortSignal.timeout(fetchConfig?.timeout) : undefined,
				}));

		if (!response.ok) {
			// For non 200 status codes
			return {
				state: 'failed',
				reason: 'backend',
				details: 'Unhandled error response received',
				errorCode: response.status,
			};
		}

		if (!response.body) {
			return {
				state: 'failed',
				reason: 'backend',
				details: 'response.body missing',
				errorCode: response.status,
			};
		}

		try {
			const reader = response.body.getReader();
			const decoder = new TextDecoder('utf-8');
			let buffer = '';
			let done = false;

			let finalResponse: AIAnswerQueryStreamMarkdownFinalPart['value'] | undefined;

			yield {
				type: AIAnswerQueryStreamType.AnswerType,
				message: {
					format: NLPSearchResultFormat.MARKDOWN,
				},
			};

			while (!done) {
				const { value, done: doneReading } = await reader.read();

				done = doneReading;
				const chunkValue = decoder.decode(value);
				buffer = buffer + chunkValue;

				// Split the buffer by line breaks
				const lines = buffer.split('\n');
				// Process all complete lines, except for the last one (which might be incomplete)

				while (lines.length > 1) {
					const line = lines.shift()!;

					const parsedData = mapStreamResponse(JSON.parse(line));

					if (parsedData.type === 'other') {
						continue;
					} else if (
						parsedData.type === AIAnswerQueryStreamType.AnswerType ||
						parsedData.type === AIAnswerQueryStreamType.AnswerPart
					) {
						if (generatorConfig.yieldAnswerPart) {
							yield parsedData;
						}
					} else if (parsedData.type === 'follow-up') {
						if (!finalResponse?.message || !variables?.followUpsEnabled) {
							continue;
						}

						finalResponse.message.nlpFollowUpResults = {
							followUps: parsedData.queries,
						};

						done = true;
					} else {
						finalResponse = parsedData;
						onStreamFinalResponse?.();

						if (!variables?.followUpsEnabled) {
							done = true;
						}

						if (generatorConfig.yieldFinalResponse) {
							yield finalResponse;
						}
					}
				}
				// Keep the last (potentially incomplete) line in the buffer
				buffer = lines[0];
			}

			if (finalResponse?.type !== AIAnswerQueryStreamType.FinalResponse) {
				return {
					state: 'failed',
					reason: 'parsing',
					error: 'Unexpected final response value',
				};
			}

			return { state: 'complete', value: finalResponse };
		} catch (parsingError: unknown) {
			return { state: 'failed', reason: 'parsing', error: parsingError };
		}
	} catch (error: unknown) {
		if (error instanceof DOMException && error.name === 'AbortError') {
			// this is likely an abort error
			return { state: 'failed', reason: 'aborted' };
		}

		if (error instanceof DOMException && error.name === 'TimeoutError') {
			return { state: 'failed', reason: 'timeout' };
		}

		if (error instanceof Error) {
			return { state: 'failed', reason: 'error', details: error.message, error };
		}

		return { state: 'failed', reason: 'unknown' };
	}
}

export async function sainRestFetchHandlerAssistanceService({
	variables,
	isReadingAids,
	fetchConfig,
}: AskSainFetchHandlerArgs & {
	isReadingAids: boolean;
}): Promise<SearchAIAnswerQueryType> {
	const { experience, named_id } = getAgentAndExperience(isReadingAids);

	const url = `${
		fetchConfig?.hostName || ''
	}${ASSISTANCE_SERVICE_API_BASE_URL}/chat/v1/invoke_agent`;

	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-Product': 'confluence',
			'X-Experience-Id': experience,
			...fetchConfig?.headers,
		},
		body: JSON.stringify({
			recipient_agent_named_id: named_id,
			agent_input: getAgentInput({ variables, fetchConfig }),
		}),
		credentials: fetchConfig?.credentials || 'same-origin', // fetch() uses 'same-origin' by default
		signal: fetchConfig?.timeout ? AbortSignal.timeout(fetchConfig?.timeout) : undefined,
	});

	const data: AgentResponse | AgentError = await response.json();

	if ('message_template' in data) {
		return {
			nlpSearch: {
				errorState: mapErrorToSain(data),
				disclaimer: null,
				format: NLPSearchResultFormat.MARKDOWN,
				uniqueSources: [],
			},
		};
	}

	return {
		nlpSearch: mapMessageToNLPSearchType(data),
	};
}
