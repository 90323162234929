/** Types copied from platform/packages/ai-mate/conversation-assistant/src/services/assistance-service/types.ts for now */

type ModelUsage = {
	total_tokens: number;
	prompt_tokens: number;
	completion_tokens: number;
	request_count: number;
	total_cost: number;
	duration: number;
};

type Usage = {
	model_usage: {
		[key: string]: ModelUsage;
	};
	total: ModelUsage;
};

type Durations = {
	events: {
		[key: string]: number;
	};
	total: number;
};

export type Source = {
	ari: string;
	title: string;
	type: string;
	url: string;
	lastModified: string;
	message_id: number;
	id: number;
};

export type Sources = Array<Source>;

export type PluginInvocation = {
	id: number | null;
	plugin_name: string | null;
	plugin_invocation_status: `PLUGIN_STARTED` | `PLUGIN_FINISHED` | null;
	plugin_invocation_time: string | null;
	plugin_description: string | null;
	plugin_input: string | null;
	plugin_output: string | null;
	plugin_error_message: string | null;
};

export type PluginInvocationMessage = PluginInvocation & {
	message_id: number | null;
};

export enum NLPSearchDisclaimerType {
	WhoDisclaimer = 'WHO_QUESTION',
}

export type Metadata = {
	bm25Variant: string;
	confidenceScore: number;
	isFallbackMessage: boolean;
	originalQuery: string;
	semanticSearchLocationVariant: string;
	semanticSearchVariant: string;
	disclaimer: NLPSearchDisclaimerType | null;
};

export type Appendix = {
	type: 'requestForm' | 'helpDesk';
	content: string;
	appendix_sources?: Sources;
};

export type Appendices = Array<Appendix>;

export type Message = {
	role: string;
	content: string;
	id: number;
	time_created: string;
	user_ari: string;
	plugin_invocations: PluginInvocationMessage[];
	sources?: Sources;
	message_metadata?: Metadata;
	author?: {
		actor_type: string;
		ari: string | null;
		id: string;
		name: string;
		named_id: string;
	};
	author_id?: string;
	experience_id?: string;
	conversation_channel_id?: string;
	appendices?: Appendices;
	content_mime_type: string;
};

type AssistantMessage = Message & {
	role: 'ASSISTANT';
};

export type AgentError = {
	content: string;
	message_template: 'NO_ANSWER_LLM' | 'NO_ANSWER_PEOPLE';
};

export type AgentResponse = {
	message: AssistantMessage;
	usage_data?: Usage;
	durations_data?: Durations;
};

export type StreamResponse = {
	type: 'FINAL_RESPONSE';
	message: AgentResponse;
	millisOffset?: number;
	metadata?: {
		request_id?: string;
	};
};

export type StreamError = {
	type: 'ERROR';
	message: {
		content: string;
		status_code?: number;
		message_template?: string;
	};
	millisOffset: number;
	metadata: null | {
		error_message?: string;
		request_id?: string;
		timeout?: number;
	};
};

export type StreamAnswerPart = {
	type: 'ANSWER_PART';
	message: { content: string; role: 'ASSISTANT' };
	millisOffset: number;
	metadata?: {
		run_id?: string;
		request_id?: string;
	};
};

export type StreamTrace = {
	type: 'TRACE';
	message: { content: string };
	millisOffset: number;
	metadata?: {
		run_id: string;
		request_id: string;
		plugin_name?: string;
		plugin_input?: string;
	};
};

export type StreamPluginInvocation = {
	type: 'PLUGIN_INVOCATION';
	message: PluginInvocation;
	millisOffset: number;
	metadata?: { run_id: string; request_id: string };
};

export type StreamFollowUp = {
	type: 'FOLLOW_UP_QUERIES';
	message: {
		follow_up_queries: string[] | null;
	};
	millisOffset: number;
	metadata?: { request_id: string };
};

export type StreamMessage =
	| StreamTrace
	| StreamPluginInvocation
	| StreamAnswerPart
	| StreamResponse
	| StreamError
	| StreamFollowUp;

export const isStreamResponse = (message: any): message is StreamResponse =>
	message instanceof Object && message.type === 'FINAL_RESPONSE';

export const isStreamError = (message: any): message is StreamError =>
	message instanceof Object && message.type === 'ERROR';

export const isStreamAnswerPart = (message: any): message is StreamAnswerPart =>
	message instanceof Object && message.type === 'ANSWER_PART';

export const isStreamTrace = (message: any): message is StreamTrace =>
	message instanceof Object && message.type === 'TRACE';

export const isStreamPluginInvocation = (message: any): message is StreamPluginInvocation =>
	message instanceof Object && message.type === 'PLUGIN_INVOCATION';

export const isStreamFollowUp = (message: any): message is StreamFollowUp =>
	message instanceof Object && message.type === 'FOLLOW_UP_QUERIES';
