import { type ArtifactMetadata, FlightDeckClient } from '@atlassiansox/flight-deck-frontend-client';

import { type HydratorMap } from '../../utils/hydrator/types';
import { type ClientHandlers } from '../activity-client';

import BundledArtifact from './default-data/recent-work-service-artifact';

const RECENT_WORK_ARTIFACT = 'recent-work-service';
const FD_ENVIRONMENT = 'prod';

type HydratorConfig = {
	fragments: string;
	hydrators: Record<string, HydratorMap>;
};
type TranslatedEventTypes = {
	absolute: string;
	relative: string;
	standalone: string;
	multiAbsolute: string;
	multiRelative: string;
	multiStandalone: string;
};

export type TranslatedEventTypeMessages = Record<string, TranslatedEventTypes>;

export type FlightDeckConfig = {
	hydrators: Record<string, HydratorMap>;
	queryFragments: string;
	localization: {
		eventTypes: TranslatedEventTypeMessages;
	};
};

export const FD_CLIENT_FETCH_PROPS: ArtifactMetadata = {
	name: RECENT_WORK_ARTIFACT,
	dimensions: {
		environmentType: FD_ENVIRONMENT,
	},
};

export default class HydratorConfigClient {
	private readonly flightDeck: FlightDeckClient;
	private readonly artifactFetch?: () => Promise<FlightDeckConfig>;
	constructor(
		handlers: ClientHandlers,
		product?: string,
		artifactFetch?: () => Promise<FlightDeckConfig>,
	) {
		this.flightDeck = new FlightDeckClient(
			product || 'recent-work-client',
			{
				handlers,
			},
			[
				{
					name: 'recent-work-service',
					dimensions: {
						environmentType: FD_ENVIRONMENT,
					},
					value: BundledArtifact,
				},
			],
		);
		this.artifactFetch = artifactFetch;
	}

	private async fetchArtifact() {
		if (this.artifactFetch) {
			return this.artifactFetch();
		}
		return this.flightDeck.fetchConfigFromFlightDeck(
			FD_CLIENT_FETCH_PROPS,
			// Retries are for the background refresh, so not too aggressive
			{ intervalsMS: [100, 500] },
		) as Promise<FlightDeckConfig>;
	}

	fetchConfig = async (): Promise<HydratorConfig> => {
		const artifact = await this.fetchArtifact();

		const { queryFragments: fragments, hydrators } = artifact;
		return {
			fragments,
			hydrators,
		};
	};
}
